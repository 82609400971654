'use client';

import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className='bg-white'>
      <div className='relative'>
        <div className='bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
          <img
            alt=''
            src='/images/main.jpg'
            className='aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full'
          />
        </div>
        <div className='mx-auto max-w-7xl'>
          <div className='relative z-10 pt-14 lg:w-full lg:max-w-2xl'>
            <svg
              viewBox='0 0 100 100'
              preserveAspectRatio='none'
              aria-hidden='true'
              className='absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block'
            >
              <polygon points='0,0 90,0 50,100 0,100' />
            </svg>

            <div className='relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0'>
              <div className='mx-auto max-w-2xl lg:mx-0 lg:max-w-xl'>
                <div className='hidden sm:mb-10 sm:flex'>
                  {/* <div className='relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
                    Anim aute id magna aliqua ad ad non deserunt sunt.{' '}
                    <a
                      href='#'
                      className='whitespace-nowrap font-semibold text-indigo-600'
                    >
                      <span aria-hidden='true' className='absolute inset-0' />
                      Read more <span aria-hidden='true'>&rarr;</span>
                    </a>
                  </div> */}
                </div>
                <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
                  Σύντομα κοντά σας με μεγάλη γκάμα προϊόντων!
                </h1>
                <p className='mt-6 text-lg leading-8 text-gray-600'>
                  Η MGYI δραστηριοποιείται στον χώρο της ενοικίαση εργαλειών και
                  μηχανημάτων και σύντoμα θα είναι κοντά σας με μεγάλη γκάμα
                  προϊόντων.
                </p>
                <div className='mt-10 flex items-center gap-x-6'>
                  {/* <Link
                    to='/docs/HILTI-Präsentation-Gerätekatalog.pdf'
                    className='rounded-md bg-amber-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500'
                    target='blank'
                  >
                    Λήψη Καταλόγου
                  </Link> */}
                  {/* <a
                    href='#'
                    className='text-sm font-semibold leading-6 text-gray-900'
                  >
                    Learn more <span aria-hidden='true'>→</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
