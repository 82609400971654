import React from 'react';
import { Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <section>
      <h1>404</h1>
      <h3>Ooops, αυτή η σελίδα δεν υφίσταται</h3>
      <Link to='/' className='btn'>
        πίσω στην αρχική
      </Link>
    </section>
  );
};

export default ErrorPage;
